
.validate-product__body td.product__update-link a {
    width: 7rem !important;
    /* height: 40px; */
    justify-content: center;
    text-decoration: none;
    font-family: "Inter", sans-serif;
}

.image__video-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Inter", sans-serif;
    
}

.front-img-vid {
   height: 292px;
   width: 292px;
   background: rgba(247, 247, 247, 1);
   border-radius: 8px;
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
   align-items: center;
   justify-content: center;
   box-shadow: 0px 2px 2px -1px inset rgba(74, 74, 104, 0.1);
   position: relative;
   font-family: "Inter", sans-serif;
}
.front-img-vid .other-imgs {
     position: relative;
     font-family: "Inter", sans-serif;
}
.front-img-vid #front-img,.other-imgs #left-img, .other-imgs #right-img, .other-imgs #rear-img, .video-upload-sec #video-upload {
     width: 100%;
     height: 100%;
     display: none;
     cursor: pointer;
}

.upload-label {
   font-family: "Inter", sans-serif;
   font-weight: 500;
   color: #9797AA;
   font-size: 12px;
}
.upload__vid_img-btn{
     width: 148px;
     height: 37px;
     color: #5C5C77;
     font-family: "Inter", sans-serif;
     font-weight: 500;
     font-size: 12px;
     background: #FBFBFB;
     border: 1px solid #9797AA;
     border-radius: 5px;
     display: flex;
     align-items: center;
     justify-content: center;
     margin-top: 0.25rem;
}
.other-imgs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}
.other-imgs label {
     width: 93px;
     height: 93px;
     border-radius: 8px;
     background: #F7F7F7;
     display: flex;
     align-items: center;
     justify-content: center;
     cursor: pointer;
     box-shadow: 0px 2px 2px -1px inset rgba(74, 74, 104, 0.1);
}
.other-imgs label input {
     display: none;
}

.video-upload-sec {
    width: 100%;
    min-height: 100px;
    height: auto;
    padding: 1rem 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border-radius: 8px;
    background: #F7F7F7;
    cursor: pointer;
    box-shadow: 0px 2px 2px -1px inset rgba(74, 74, 104, 0.1);
    position: relative;
}
 
.front__view {
    cursor: pointer !important;
}


.img__vid_update-btn {
    width: 150px;
    height: 40px;
    border: none;
    border-radius: 8px;
    background: var(--alpha-blue-back);
    color: white;
    font-family: "Inter", sans-serif;
    font-size: 14px;

}

.move-back-btn {
    display: flex;
    cursor: pointer;
    font-weight: bold;
    gap: 0.4rem;
    font-size: 14px;
    font-family: "Inter", sans-serif;
}

.other__img_n-video {
    width: 300px;
}