.revenue-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
}
.prod_card_donutchart {
     position: relative;
     cursor: pointer;
}
.prod_card_donutchart .switch-year-month-week {
    position: absolute;
    top: 0;
    right: 2em;
    z-index: 99999;

}

.weekly-monthly-yearly .card > .card-body{
    display: flex;
    flex-direction: column;
    align-items: center !important;
  
}

.revenue-on-item {
    display: flex;
    align-items: center;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    letter-spacing: 1.5px;
    color: #00B412;
}

.sale-header-analytics {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.5px;
    color: #192150
}
.revenue-number {
      font-size: 1.6em !important;
}

.revenue-number, .revenue-icon {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 16px;
    letter-spacing: 1.5px;    
    color: #1A1A1A;
}
.revenue-icon {
    background: rgba(25, 33, 80, 0.1);
    border-radius: 30px;
    padding: .3rem;
}
.sales-card {
    height: 158px;
}

.sold-product-icon {
    color: #D78E00;
    background: rgba(215, 142, 0, 0.1);
}
.sold-product {
    color: #D78E00;
}

.sold-revenue-on-item {
      color: var(--alpha-red)   
}

.sale-product-uploaded {
    color: #1ABB00;
}
.product-upload-icon {
    color: #1ABB00;
    background: rgba(26, 187, 0, 0.1);
}
.visit-site {
    color: #009CF4;
}
.site-visit-icon {
    color: #009CF4;
    background: rgba(0, 156, 244, 0.1);
}
.header___two p {
    margin-top: 1.3rem;
    margin-left: 1.5rem;
    margin-bottom: 0;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #1A1A1A;
    padding: .5rem;
}
.overview__hr {
    border-bottom: 1px solid rgba(51, 51, 51, 0.4) !important;
}
.header___one {
   display: flex;
   justify-content: space-between;
   align-items: center;   
}
.header___one p {
    margin-top: 1.3rem;
    margin-left: 1.5rem;
    margin-bottom: 0;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #1A1A1A;
}

.header___one .select-sales-date {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.header___one .select-sales-date .date-range {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 1.3rem;
    margin-bottom: 0;
    border-radius: 5px;
    border: 1px solid var(--alpha-40);
    padding: .3rem .7rem;
    min-width: 150px;
    width: auto;
    position: relative;
}
.header___one .select-sales-date .date-range > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
}
.header___one .select-sales-date .date-range > div span{
    font-size: 14px;
    color: var(--alpha-40);
}

.switch-year-month-week .date__range-picker{
   display: flex;
   justify-content: center;
   gap: 0.5rem;

}
.switch-year-month-week .date__range-picker .react-datepicker-wrapper {
    width: 150px !important;
    display: flex;
    justify-content: center;
}
.switch-year-month-week .date__range-picker .react-datepicker-wrapper input {
    width: 100%;
}

.btn-data-sort {
    margin-top: 1.3rem;
    margin-right: 1.5rem;
    margin-bottom: 0;
}
.btn-data-sort button {
    border: 1px solid rgba(51, 51, 51, 0.1);
    background: #ffffff;
    font-size: 14px;
}
.btn-data-sort .yearly-btn, .btn-data-sort .monthly-btn, .btn-data-sort .weekly-btn {
    padding: .3rem .7rem;
}

.btn-data-sort .yearly-btn {
    border-radius: 0px 7px 7px 0px;
}

.btn-data-sort .weekly-btn {
    border-radius: 7px 0px 0px 7px;
}

.active-review-btn {
    background: var(--alpha-80) !important;
    color: #ffffff;
    transition: all .3s ease-in-out;
}

.sale_table  p{
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.25px;
    color: #333333;
    padding: 1.5rem 1rem;
    margin-bottom: -1rem;
}

.sale-table-header th{
    font-family: Circular Std;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    color: var(--alpha-80);
}

.sale-table-ordered {
    color: #E9D205;
}

.sale-table-transit {
    color: #72FFC9;
}


.calender-btn-style {
     margin: 1rem 0;
     display: flex;
     justify-content: flex-end;
     gap: 1rem;
}
.calender-btn-style button {
      height: 40px;
      width: 80px;
      border-radius: 8px;
      outline: none;
      border: none;
      box-shadow: 0 0 5px rgba(0, 156, 244, 0.1);
}
.calender-btn-style .apply-btn {
     background: var(--alpha-blue-back);
     color: var(--text-white);
     transition: all 0.3s ease;
} 
.calender-btn-style .apply-btn:hover {
      background: var(--text-white);
      color: var(--alpha-blue-back);
      border: 1px solid var(--alpha-blue-back);
}
.calender-btn-style .close-btn {
    background: var(--alpha-40);
    color: var(--alpha-blue-back);
    transition: all 0.3s ease;
}
.calender-btn-style .close-btn:hover {
    background: var(--alpha-blue-back);
    color: var(--text-white);
    
}
.switch-year-month-week .react-datepicker__input-container {
      border: none !important;
}
.switch-year-month-week input{
      background: var(--alpha-10) !important;
      padding: 0.5rem 1rem;
      cursor: pointer;
      border: none !important;
      outline: none !important;
      border-radius: 8px !important;

}